<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 746.69 216.99"
  >
    <path
      d="M407.9,126.91h-59.2c-.38,0-.52,.07-.54,.08,0,0-.04,.1-.04,.34v31.78h59.78c4.99,0,8.5-1.03,10.43-3.06,1.96-2.05,2.95-5.31,2.95-9.68v-6.72c0-4.38-.99-7.64-2.95-9.68-1.94-2.03-5.45-3.06-10.43-3.06Z"
      fill="none"
    />
    <path
      d="M157.35,101.99h-17.12c-2.43,0-4.55,.17-6.28,.51-1.85,.36-3.53,1.06-4.98,2.09-1.43,1.01-2.72,2.39-3.83,4.08-1.06,1.62-2.16,3.77-3.24,6.39l-33.27,76.79c-.19,.42-.4,.55-.87,.55h-1.92c-.47,0-.69-.13-.86-.54L51.69,115.07c-1.1-2.63-2.19-4.79-3.25-6.41-1.12-1.7-2.4-3.07-3.83-4.08-1.45-1.03-3.16-1.73-5.07-2.09-1.79-.34-3.93-.51-6.36-.51H15.75c-6.2,0-10.31,1.42-12.55,4.35C1.05,109.14,0,113.65,0,120.13v91.84c0,3.19,1.89,5.02,5.18,5.02H21.51c3.19,0,5.02-1.83,5.02-5.02v-83.52s0-.07,0-.1h1.22c.56,0,.62,.15,.71,.4l32.32,71.67c1.31,2.95,2.67,5.48,4.05,7.51,1.4,2.08,2.95,3.83,4.61,5.2,1.7,1.41,3.67,2.43,5.85,3.01,2.09,.56,4.56,.85,7.35,.85h7.68c2.79,0,5.26-.29,7.35-.85,2.17-.59,4.14-1.6,5.85-3.01,1.65-1.37,3.2-3.11,4.61-5.2,1.38-2.04,2.74-4.57,4.04-7.5l32.36-71.77c.06-.16,.12-.31,.68-.31h1.22s0,.06,0,.1v83.52c0,3.19,1.83,5.02,5.02,5.02h16.32c3.29,0,5.18-1.83,5.18-5.02V120.13c0-6.48-1.04-10.99-3.19-13.79-2.21-2.88-6.38-4.35-12.39-4.35Z"
      fill="currentColor"
    />
    <path
      d="M303.27,101.99h-70.4c-8.2,0-15.27,.8-21.04,2.37-5.87,1.61-10.74,4.2-14.47,7.71-3.75,3.52-6.52,8.13-8.25,13.69-1.69,5.45-2.55,12.17-2.55,19.96v27.52c0,7.8,.86,14.54,2.55,20.04,1.72,5.62,4.5,10.25,8.25,13.77,3.74,3.52,8.61,6.09,14.48,7.64,5.75,1.52,12.82,2.29,21.02,2.29h70.4c3.29,0,5.18-1.83,5.18-5.02v-15.2c0-3.19-1.89-5.02-5.18-5.02h-69.92c-4.02,0-7.37-.33-9.95-.99-2.44-.62-4.42-1.67-5.87-3.13-1.44-1.44-2.46-3.46-3.04-5.99-.61-2.7-.92-6.12-.92-10.15v-1.22h88.9c3.19,0,5.02-1.83,5.02-5.02v-12.64c0-3.19-1.83-5.02-5.02-5.02h-88.9v-.1c0-4.03,.31-7.44,.92-10.15,.57-2.53,1.6-4.55,3.04-5.99,1.45-1.45,3.43-2.5,5.87-3.13,2.59-.66,5.94-.99,9.95-.99h69.92c3.29,0,5.18-1.83,5.18-5.02v-15.2c0-3.19-1.89-5.02-5.18-5.02Z"
      fill="currentColor"
    />
    <path
      d="M426.14,178.54c7.55-1.77,13.18-5.25,16.76-10.35,3.9-5.57,5.87-13.18,5.87-22.62v-9.12c0-11.23-2.83-19.87-8.42-25.68-5.6-5.83-14.85-8.78-27.48-8.78h-85.92c-4,0-6.3,2.3-6.3,6.3v103.68c0,3.19,1.89,5.02,5.18,5.02h17.28c3.19,0,5.02-1.83,5.02-5.02v-30.34h49.44l24.8,32.65c.66,.93,1.33,1.58,2.05,2.01,.79,.47,1.95,.69,3.65,.69h20.64c3.13,0,3.86-1.96,4.03-2.81,.38-1.9-.22-3.05-.82-3.69l-25.77-31.95Zm-4.85-38.9v6.72c0,4.38-.99,7.64-2.95,9.68-1.94,2.03-5.45,3.06-10.43,3.06h-59.78v-31.78c0-.23,.04-.33,.04-.34,.02-.01,.17-.08,.54-.08h59.2c4.99,0,8.5,1.03,10.43,3.06,1.96,2.05,2.95,5.31,2.95,9.68Z"
      fill="currentColor"
    />
    <path
      d="M576.55,101.99h-72.16c-8.2,0-15.27,.8-21.04,2.37-5.87,1.61-10.74,4.2-14.47,7.71-3.75,3.52-6.52,8.13-8.25,13.69-1.69,5.45-2.55,12.17-2.55,19.96v27.52c0,7.8,.86,14.54,2.55,20.04,1.72,5.62,4.5,10.25,8.25,13.77,3.74,3.52,8.62,6.09,14.48,7.64,5.75,1.52,12.82,2.29,21.02,2.29h72.16c3.19,0,5.02-1.83,5.02-5.02v-15.68c0-3.19-1.83-5.02-5.02-5.02h-71.2c-4.02,0-7.37-.33-9.95-.99-2.44-.62-4.38-1.67-5.77-3.11-1.41-1.45-2.44-3.48-3.06-6.03-.66-2.69-1-6.15-1-10.28v-22.72c0-4.13,.33-7.56,1-10.2,.62-2.5,1.66-4.5,3.06-5.96,1.39-1.44,3.34-2.49,5.77-3.11,2.59-.66,5.94-.99,9.95-.99h71.2c3.19,0,5.02-1.89,5.02-5.18v-15.68c0-3.19-1.83-5.02-5.02-5.02Z"
      fill="currentColor"
    />
    <path
      d="M714.14,101.99h-17.28c-3.19,0-5.02,1.83-5.02,5.02v40.1h-71.88v-40.1c0-3.19-1.83-5.02-5.02-5.02h-17.28c-3.29,0-5.18,1.83-5.18,5.02v104.96c0,3.19,1.89,5.02,5.18,5.02h17.28c3.19,0,5.02-1.83,5.02-5.02v-41.06h71.88v41.06c0,3.19,1.83,5.02,5.02,5.02h17.28c3.29,0,5.18-1.83,5.18-5.02V107.01c0-3.19-1.89-5.02-5.18-5.02Z"
      fill="currentColor"
    />
    <path
      d="M467.19,22.54c-.97-1-2.34-1.74-4.06-2.18-1.88-.48-4.32-.72-7.26-.72h-35.22c-2.94,0-5.38,.24-7.26,.72-1.72,.44-3.09,1.17-4.06,2.18-.98,1.02-1.71,2.45-2.15,4.26-.48,1.96-.73,4.45-.73,7.39v17.79c0,2.95,.24,5.43,.73,7.39,.44,1.81,1.17,3.24,2.15,4.26,.97,1.01,2.34,1.74,4.06,2.18,1.88,.48,4.32,.72,7.26,.72h35.22c2.94,0,5.39-.24,7.26-.72,1.72-.44,3.09-1.17,4.06-2.18,.98-1.01,1.7-2.45,2.15-4.26,.48-1.96,.73-4.45,.73-7.39v-17.79c0-2.94-.24-5.43-.73-7.39-.45-1.81-1.17-3.24-2.15-4.26Z"
      fill="none"
    />
    <path
      d="M57.86,19.99h-.54s-.01,.02-.02,.03l-17.35,32.39h35.51L57.87,20s0,0,0,0Z"
      fill="none"
    />
    <path
      d="M695.23,22.54c-.97-1-2.34-1.74-4.06-2.18-1.88-.48-4.32-.72-7.26-.72h-35.22c-2.94,0-5.38,.24-7.26,.72-1.72,.44-3.09,1.17-4.06,2.18-.98,1.02-1.71,2.45-2.15,4.26-.48,1.96-.73,4.44-.73,7.39v17.79c0,2.95,.24,5.44,.73,7.39,.44,1.81,1.17,3.24,2.15,4.26,.97,1.01,2.34,1.74,4.06,2.18,1.88,.48,4.32,.72,7.26,.72h35.22c2.94,0,5.38-.24,7.26-.72,1.72-.44,3.09-1.17,4.06-2.18,.98-1.02,1.71-2.45,2.15-4.26,.48-1.96,.73-4.45,.73-7.39v-17.79c0-2.94-.24-5.43-.73-7.39-.44-1.81-1.17-3.24-2.15-4.26Z"
      fill="none"
    />
    <path
      d="M348.44,19.4h-43.83v22.93s43.83,0,43.83,0c3.57,0,6.05-.71,7.4-2.12,1.36-1.43,2.05-3.73,2.05-6.86v-4.98c0-3.12-.69-5.43-2.05-6.86-1.34-1.41-3.83-2.12-7.4-2.12Z"
      fill="none"
    />
    <path
      d="M362.77,57.48c5.35-1.39,9.37-3.96,11.98-7.68,2.94-4.21,4.44-9.94,4.44-17.03v-6.76c0-8.45-2.14-14.96-6.37-19.36-4.25-4.41-11.21-6.65-20.7-6.65H124.71c-7.61,0-13.34,1.96-17.03,5.82-3.67,3.84-5.54,8.92-5.54,15.08v8.66c0,6.96,1.91,12.42,5.67,16.23,3.77,3.81,9.37,5.74,16.66,5.74h44.47c3.22,0,4.59,1.51,4.59,5.06v4.86c0,1.68-.36,2.98-1.08,3.88-.63,.79-1.85,1.19-3.63,1.19h-62.26L75.34,9.82c-2.13-3.84-4.3-6.38-6.62-7.76-2.3-1.37-5.12-2.06-8.4-2.06h-7c-2.83,0-5.35,.7-7.5,2.09-2.14,1.38-4.21,3.91-6.34,7.74L.56,81.15c-.6,1.2-.72,2.29-.36,3.22,.31,.82,1.14,1.79,3.31,1.79h15.42c1.86,0,3.28-.82,4.12-2.37l7.74-14.46h53.86l7.95,14.44c.74,1.55,2.21,2.4,4.13,2.4h75.54c7.61,0,13.34-1.96,17.03-5.82,3.67-3.85,5.54-8.92,5.54-15.08v-9.49c0-6.96-1.91-12.42-5.67-16.23-3.77-3.81-9.38-5.74-16.66-5.74h-44.47c-3.22,0-4.59-1.51-4.59-5.06v-4.03c0-1.68,.36-2.98,1.08-3.88,.63-.79,1.85-1.19,3.63-1.19l99.5,.19v62.16c0,2.62,1.57,4.19,4.19,4.19h12.93c2.62,0,4.19-1.57,4.19-4.19V20.11h34.35v61.87c0,2.62,1.61,4.19,4.31,4.19h12.81c2.62,0,4.19-1.57,4.19-4.19v-22.02h35.94l18.23,24c.53,.74,1.07,1.27,1.66,1.62,.66,.4,1.6,.58,2.95,.58h15.3c2.66,0,3.29-1.71,3.44-2.46,.32-1.59-.2-2.57-.72-3.13l-18.63-23.1ZM39.95,52.41L57.3,20.03s.01-.02,.02-.03h.54s0,0,0,0l17.59,32.42H39.95Zm317.94-19.06c0,3.12-.69,5.43-2.05,6.86-1.34,1.41-3.83,2.12-7.4,2.12h-43.84V19.4h43.84c3.56,0,6.05,.71,7.4,2.12,1.36,1.43,2.05,3.74,2.05,6.86v4.98Z"
      fill="currentColor"
    />
    <path
      d="M483.34,7.6c-2.83-2.66-6.52-4.62-10.98-5.83-4.35-1.18-9.66-1.77-15.77-1.77h-36.64c-6.12,0-11.4,.6-15.71,1.78-4.42,1.21-8.1,3.17-10.92,5.83-2.84,2.67-4.94,6.15-6.24,10.35-1.27,4.08-1.91,9.11-1.91,14.93v20.4c0,5.83,.64,10.87,1.91,14.99,1.3,4.24,3.4,7.74,6.24,10.41,2.83,2.66,6.51,4.6,10.94,5.77,4.3,1.14,9.58,1.71,15.7,1.71h36.64c6.12,0,11.42-.58,15.76-1.71,4.46-1.17,8.16-3.11,11-5.77,2.84-2.67,4.92-6.18,6.18-10.42,1.22-4.12,1.84-9.16,1.84-14.98v-20.4c0-5.82-.62-10.84-1.85-14.92-1.26-4.21-3.34-7.69-6.18-10.36Zm-13.27,44.37c0,2.94-.24,5.43-.73,7.39-.45,1.81-1.17,3.25-2.15,4.26-.97,1.01-2.34,1.74-4.06,2.18-1.87,.48-4.32,.72-7.26,.72h-35.22c-2.94,0-5.38-.24-7.26-.72-1.72-.44-3.09-1.17-4.06-2.18-.98-1.02-1.71-2.45-2.15-4.26-.48-1.96-.73-4.44-.73-7.39v-17.79c0-2.94,.24-5.43,.73-7.39,.44-1.81,1.17-3.24,2.15-4.26,.97-1,2.34-1.74,4.06-2.18,1.88-.48,4.32-.72,7.26-.72h35.22c2.94,0,5.38,.24,7.26,.72,1.72,.44,3.09,1.17,4.06,2.18,.98,1.02,1.71,2.45,2.15,4.26,.48,1.96,.73,4.45,.73,7.39v17.79Z"
      fill="currentColor"
    />
    <path
      d="M599.72,0h-12.45c-2.62,0-4.19,1.57-4.19,4.19v61.51h-.44c-.13,0-.17-.02-.17-.03l-44.22-58.03c-1.17-1.58-2.18-2.81-3.1-3.77-.98-1.02-2.03-1.84-3.11-2.43-1.12-.6-2.42-1-3.88-1.19-1.33-.17-2.99-.25-4.94-.25h-10.44c-4.05,0-7.13,1.01-9.17,3-2.07,2.03-3.08,5.6-3.08,10.91V81.98c0,2.62,1.61,4.19,4.31,4.19h12.45c2.62,0,4.19-1.57,4.19-4.19V20.47h.44s.16,.07,.29,.21l44.22,57.86c1.25,1.66,2.32,2.94,3.29,3.9,1.02,1.02,2.1,1.82,3.2,2.37,1.11,.55,2.37,.92,3.76,1.1,1.3,.17,2.86,.25,4.77,.25h10.2c4.05,0,7.13-1.01,9.17-3,2.07-2.03,3.08-5.6,3.08-10.91V4.19c0-2.62-1.57-4.19-4.19-4.19Z"
      fill="currentColor"
    />
    <path
      d="M717.56,17.96c-1.26-4.2-3.34-7.69-6.18-10.36-2.83-2.66-6.52-4.62-10.98-5.83-4.35-1.18-9.66-1.77-15.77-1.77h-36.64c-6.12,0-11.4,.6-15.71,1.78-4.42,1.21-8.1,3.17-10.92,5.83-2.84,2.67-4.93,6.15-6.24,10.35-1.27,4.09-1.91,9.11-1.91,14.93v20.4c0,5.83,.64,10.87,1.91,14.99,1.3,4.24,3.4,7.74,6.24,10.41,2.83,2.66,6.51,4.6,10.93,5.77,4.3,1.14,9.58,1.71,15.7,1.71h36.64c6.12,0,11.42-.58,15.76-1.71,4.46-1.17,8.16-3.11,11-5.77,2.84-2.67,4.92-6.18,6.18-10.42,1.22-4.12,1.84-9.16,1.84-14.98v-20.4c0-5.82-.62-10.84-1.85-14.92Zm-19.45,34.01c0,2.94-.24,5.43-.73,7.39-.44,1.81-1.17,3.24-2.15,4.26-.97,1.01-2.34,1.74-4.06,2.18-1.87,.48-4.32,.72-7.26,.72h-35.22c-2.94,0-5.38-.24-7.26-.72-1.72-.44-3.09-1.17-4.06-2.18-.98-1.02-1.71-2.45-2.15-4.26-.48-1.96-.73-4.44-.73-7.39v-17.79c0-2.95,.24-5.43,.73-7.39,.44-1.81,1.17-3.24,2.15-4.26,.97-1.01,2.34-1.74,4.06-2.18,1.88-.48,4.32-.72,7.26-.72h35.22c2.94,0,5.38,.24,7.26,.72,1.72,.44,3.09,1.17,4.06,2.18,.98,1.02,1.71,2.45,2.15,4.26,.48,1.97,.73,4.45,.73,7.39v17.79Z"
      fill="currentColor"
    />
    <path
      d="M725.94,114.78v-10.58h-3.95v-2.22h10.39v2.22h-3.84v10.58h-2.59Zm7.98,0v-12.8h4.05l2.33,8.81,2.34-8.81h4.05v12.8h-2.47v-10.2l-2.77,10.2h-2.34l-2.75-10.2v10.2h-2.44Z"
      fill="currentColor"
    />
  </svg>
</template>
